<template>
  <div class="account-profile">
    <div class="is-flex justify-space-between align-items-center">
      <div class="_fs-16 _fw-500 has-text-black">{{$t('accountPage.profile')}}</div>
      <SmallButton type="outline" @click.native="$router.push({ name: 'EditProfile'})">{{$t('accountPage.editProfile')}}</SmallButton>
    </div>
    <div class="has-margin-top-25 _fw-500 _fs-16">
      <div class="is-flex">
        <div class="p-title has-text-black40">{{$t('accountPage.name')}}</div>
        <div class="flex-1 has-margin-left-15">{{user && user.first_name || ''}} {{user && user.last_name || ''}}</div>
      </div>
      <div class="is-flex has-margin-top-10">
        <div class="p-title has-text-black40">{{$t('accountPage.dateOfBirth')}}</div>
        <div class="flex-1 has-margin-left-15">{{ birthDate }}</div>
      </div>
      <div class="is-flex has-margin-top-10">
        <div class="p-title has-text-black40">{{$t('accountPage.gender')}}</div>
        <div class="flex-1 has-margin-left-15">{{ user && user.gender || '' }}</div>
      </div>
      <div class="is-flex has-margin-top-10">
        <div class="p-title has-text-black40">{{$t('accountPage.phoneNumber')}}</div>
        <div class="flex-1 has-margin-left-15">{{ mobile }}</div>
      </div>
      <div class="is-flex has-margin-top-10">
        <div class="p-title has-text-black40">{{$t('accountPage.address')}}</div>
        <div class="flex-1 has-margin-left-15">{{ address }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import StringMask from 'string-mask'
import moment from 'moment'
import SmallButton from '@/components/base/SmallButton.vue'
export default {
  components: {
    SmallButton
  },
  computed: {
    ...mapState('Auth', ['user']),
    birthDate () {
      if (this.user && this.user.birth_date) {
        // return moment(this.user.birth_date).add(543, 'year').locale('th').format('LL')
        return moment(this.user.birth_date).locale(this.$i18n.locale).format('DD MMM YYYY')
      } else {
        return ''
      }
    },
    mobile () {
      if (this.user && this.user.mobile) {
        const formatter = new StringMask('000 000 0000')
        const result = formatter.apply(this.user.mobile)
        return result
      } else {
        return '-'
      }
    },
    address () {
      if (!this.user) {
        return ''
      }
      const a = `${this.user.address_1 || ''} ${this.user.sub_district || ''} ${this.user.district || ''} ${this.user.province || ''} ${this.user.post_code || ''}`
      return a.trim()
    }
  }
}
</script>
<style lang="scss" scoped>
.account-profile {
  padding: 16px 20px;
  background: white;

  .p-title {
    width: 110px;
  }
}
</style>
