<template>
  <div>
    <Backbar
      :title="$t('accountPage.title')"
      @onBackClick="$router.go(-1)"
    />
    <AccountHeader/>
    <div class="has-margin-top-10">
      <AccountProfile/>
    </div>
    <div class="has-margin-top-10">
      <MenuItem @click.native="isConditionModalActive = !isConditionModalActive">{{$t('accountPage.condition')}}</MenuItem>
    </div>
    <!-- <div class="has-margin-top-10 is-flex align-items-center justify-center">
      <RoundButton @click="deleteAccount()" type="danger">{{$t('accountPage.deleteAccount')}}</RoundButton>
    </div> -->
    <b-modal
      scroll="clip"
      class="justify-start"
      :active.sync="isConditionModalActive"
      :can-cancel="false"
    >
      <ConditionModal/>
    </b-modal>
  </div>
</template>

<script>
import Backbar from '@/components/Backbar.vue'

import AccountHeader from '@/components/AccountHeader.vue'
import AccountProfile from '@/components/AccountProfile.vue'

import MenuItem from '@/components/MenuItem.vue'

import ConditionModal from '@/components/modal/ConditionModal.vue'
// import RoundButton from '@/components/base/RoundButton.vue'
export default {
  name: 'Account',
  components: {
    Backbar,
    AccountHeader,
    AccountProfile,
    MenuItem,
    ConditionModal
    // RoundButton
  },
  data () {
    return {
      isConditionModalActive: false
    }
  },
  methods: {
    deleteAccount () {
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
