<template>
  <div class="account-header">
    <div class="is-flex align-items-center">
      <div class="avatar-wrapper">
        <img class="img-profile"
          :src="user && user.line_picture_url || 'https://www.w3schools.com/w3images/avatar2.png'"
          @error="replaceByDefault"
        />
      </div>
      <div class="has-margin-left-20 flex-1">
        <div class="has-text-black _fs-20 _fw-500">{{user && user.first_name || ''}} {{user && user.last_name || ''}}</div>
        <div class="_fs-12 has-text-black">{{ $t('common.memberId', { memberId: (userPackage && userPackage.code) || '-' }) }}</div>
        <span class="has-text-white _fs-10 _fw-500 has-background-primary has-padding-left-5 has-padding-right-5">{{$t('common.level', { level: $i18n.locale === 'th' ? currentMemberCard.name || '' : currentMemberCard.name_en || '' })}}</span>
        <div class="_fs-12 has-text-black40">{{ $t('accountPage.registerDate', { registerDate: registeredDate })}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'
export default {
  computed: {
    ...mapState('Auth', ['user']),
    ...mapGetters('Auth', [
      'currentMemberCard',
      'userPackage'
    ]),
    registeredDate () {
      if (this.user && this.user.registered_at) {
        // return moment(this.user.registered_at).add(543, 'year').locale('en').format('LL')
        return moment(this.user.registered_at).locale(this.$i18n.locale).format('DD MMM YYYY')
      } else {
        return ''
      }
    }
  },
  methods: {
    replaceByDefault (e) {
      e.target.src = 'https://www.w3schools.com/w3images/avatar2.png'
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.account-header {
  padding: 24px;
  background: white;
  .avatar-wrapper {
    width: 90px;
    height: 90px;
    border-radius: 100%;
    background: $greyd8;
    .img-profile {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}
</style>
